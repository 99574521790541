import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Icon } from '@common/components/icon';

type RoleRowProps = {
  item: {
    id: string;
    level: number;
    name: string;
    permissions: string[];
    options: {
      sub_level: string;
      permissions: string[];
    }[];
  };
  selectedPermissions: string[];
};

const ADMIN_LEVEL = '';
const ACCESS_FULL = 'full';
const ACCESS_PARTIAL = 'partial';
const ACCESS_NONE = 'none';

const getDescription = (type: string) => {
  return `organisation:forms_role_item_type_${type}-description`;
};

const getPreviewIcon = (accessType: string) => {
  switch (accessType) {
    case ACCESS_FULL:
      return <Icon type="check" className="RoleSelector__Group__Selected" />;
    case ACCESS_PARTIAL:
      return <Icon type="minus" className="RoleSelector__Group__PartialSelected" />;
    default:
      return <Icon type="close" />;
  }
};

const getPermissionPreviewData = (item: RoleRowProps['item'], selectedPermissions: RoleRowProps['selectedPermissions']) => {
  const context = `${item.level}-${item.name}`;
  const areSelected = (permissions: string[]) => {
    return permissions?.every((permission) => selectedPermissions.includes(permission));
  };
  if (Array.isArray(item.options)) {
    const adminPermissions = item.options.find((option) => option.sub_level === ADMIN_LEVEL);
    if (adminPermissions && areSelected(adminPermissions.permissions)) {
      return { access: ACCESS_FULL, descriptions: [getDescription(context)] };
    }
    const nonAdminDescriptions = item.options
      .filter((option) => option.sub_level !== ADMIN_LEVEL && areSelected(option.permissions))
      .map((option) => getDescription(`${item.level}-${item.name}-${option.sub_level}`));
    if (nonAdminDescriptions?.length > 0) {
      return { access: ACCESS_PARTIAL, descriptions: nonAdminDescriptions };
    }
  }
  return {
    access: areSelected(item.permissions) ? ACCESS_FULL : ACCESS_NONE,
    descriptions: [getDescription(context)]
  };
};

const RoleRow = ({ item, selectedPermissions }: RoleRowProps) => {
  const { t } = useTranslation();
  const { access, descriptions } = getPermissionPreviewData(item, selectedPermissions);
  return (
    <div className="RoleSelector__Group" key={item.id}>
      { getPreviewIcon(access) }
      <div>
        <h6>
          {t(`organisation:forms_role_item_type_${item.level}-${item.name}`)}
        </h6>
        {
          descriptions.map((description) => {
            return (
              <small key={description}>
                <Trans i18nKey={description} components={[<b>a</b>]} />
              </small>
            );
          })
        }
      </div>
    </div>
  );
};

export default RoleRow;
