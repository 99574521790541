import React, {
  PropsWithChildren, ReactElement, cloneElement, useCallback, useEffect, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { omit } from 'lodash';
import * as R from 'ramda';
import { useDispatch } from 'react-redux';

import Modal from '@common/components/modal';
import * as Alert from '@common/services/alert';
import { Button } from '@common/components/button';
import Icon from '@common/components/icon';
import AsyncList from '@common/components/list/async';
import { Checkbox } from '@common/components/form/inputs/checkbox';
import RoleRow from '@modules/admin/components/role-row';

import { updateUserRoles } from '../../actions';
import { ExtendedUser, Network, Role, UIRole } from '@common/types/objects';
import { ELevels } from '@common/definitions';

type AdminRolesFormOwnProps = PropsWithChildren<{
  show?: boolean;
  isSelect?: boolean;
  admin: ExtendedUser & { roles: (UIRole | Role)[] };
  roles: UIRole[];
  network?: Network;
  fetchGroups: (level: number) => any;
  onChange?: (roles: UIRole[]) => void;
  onClose?: () => void;
  onSuccess?: () => Promise<void>;
  updateAdmin?: (
    userId: string,
    payload: { add?: Array<string>, remove?: Array<string> },
    networkId?: string,
    roles?: UIRole[],
  ) => any;
}>;

const combineRoles = (adminRoles: (UIRole | Role)[], roles: UIRole[]) => {
  return adminRoles.reduce((acc, role) => ({
    ...acc,
    [role.id]: roles.find((r) => r.id === role.id),
  }), {});
};

const AdminRolesForm = ({
  admin, roles, network, children, show = false, isSelect = false,
  fetchGroups, updateAdmin = updateUserRoles, onClose, onSuccess, onChange,
}: AdminRolesFormOwnProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedRoles, setSelectedRoles] = useState<Record<string, UIRole>>(combineRoles(admin.roles, roles));

  useEffect(() => {
    setSelectedRoles(combineRoles(admin.roles, roles));
  }, [admin.roles, roles]);

  useEffect(() => {
    if (isVisible) onChange?.(Object.values(selectedRoles));
  }, [selectedRoles, isVisible]);

  const handleClose = useCallback(() => {
    setSubmitting(false);
    setIsVisible(false);
    setSelectedRoles(combineRoles(admin.roles, roles));
    onClose?.();
  }, [onClose, setIsVisible, setSelectedRoles, setSubmitting]);

  const handleToggleRole = useCallback((role: UIRole) => {
    if (selectedRoles[role.id]) {
      setSelectedRoles(omit(selectedRoles, [role.id]));
    } else {
      setSelectedRoles({
        ...selectedRoles,
        [role.id]: role,
      });
    }
  }, [selectedRoles, setSelectedRoles]);

  const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isSelect) {
      handleClose();
      return;
    }

    const selected = R.values(selectedRoles);
    const payload: { add?: Array<string>, remove?: Array<string> } = {
      // TODO: use lodash instead of ramda
      add: R.pluck('id' as any, R.filter((role: any) => !R.find(R.propEq('id', role.id), admin.roles), selected)),
      remove: R.pluck('id' as any, R.filter((role: any) => !R.find(R.propEq('id', role.id), selected), admin.roles)),
    };

    try {
      await dispatch(updateAdmin(admin.id, payload, network?.id, roles));
      Alert.success(t('organisation:forms_admin_success'));
      if (onSuccess) await onSuccess();
    } catch (error: any) {
      Alert.forStatus(error.status_code, {
        warning: t('organisation:forms_admin_warning'),
        error: t('organisation:forms_admin_error'),
      });
      throw error;
    } finally {
      handleClose();
    }
  }, [isSelect, selectedRoles, t, handleClose, updateAdmin, dispatch]);

  // TODO: use lodash instead of ramda
  const selectedPermissions = R.uniq(R.flatten(R.values(R.pluck('permissions' as any, selectedRoles as any))));

  return (
    <Modal
      size="large"
      show={isVisible || show}
      onClose={handleClose}
      title={t(network ? 'network:forms_admin_title' : 'organisation:forms_admin_title')}
      wrapper={Modal.FormWrapper}
      wrapperProps={{
        onSubmit: handleSubmit,
      }}
      onExited={onClose}
      content={(
        <div className="RoleSelector">
          <div className="RoleSelector__Roles">
            {[...roles].sort(({ is_global }) => (is_global ? -1 : 0)).map((role) => ( // eslint-disable-line camelcase
              <div
                key={role.id}
                className="RoleSelector__Roles__Item"
                onClick={() => handleToggleRole(role)}
              >
                <Checkbox size="large" value={!!selectedRoles[role.id]} />
                {role.name}
                {role.is_global && <Icon type="star__filled" className="GlobalRole__Icon" />}
              </div>
            ))}
          </div>
          <div className="RoleSelector__Permissions">
            <AsyncList
              key={network?.id}
              rowProps={{ selectedPermissions }}
              data={{
                onFetch: () => fetchGroups(network ? ELevels.NETWORK : ELevels.ORGANISATION),
                useCursor: undefined,
              }}
              renderRow={RoleRow}
            />
          </div>
        </div>
      )}
      footer={(
        <Button
          type="primary"
          isLoading={submitting}
          buttonType={isSelect ? 'button' : 'submit'}
          onClick={isSelect ? handleClose : undefined}
        >
          {t('organisation:forms_admins_edit')}
        </Button>
      )}
    >
      {children && cloneElement(children as ReactElement, { onClick: () => setIsVisible(!isVisible) })}
    </Modal>
  );
};

export default AdminRolesForm;
